import React, {Component} from "react";
import './TopNav.scss';
import { Nav, FormGroup, Input, Button, NavLink } from 'reactstrap';
import AuthModal from '../AuthModal/AuthModal'
// import { Auth } from 'aws-amplify';
import { AmplifySignOut } from '@aws-amplify/ui-react';
// import OutsideAlerter from "../OutsideClose/OutsideClose";
// import MobileMenu from '../MobileMenu/MobileMenu'

class TopNav extends Component {

    state = {
        displayLogin: false,
        displaySignUp: false,
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleKeypress);

    }
    handleLoginClick() {
        this.setState({displayLogin: true})
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside = (event) => {
        const className = event.target.className
        if (className === "authModal") {
            this.setState({displayLogin: false})
        }
    }
    handleKeypress = (event) => {
        console.log(event.code)
        if(event.code === "Escape"){
            this.setState({displayLogin: false})
        }

    }
    handleSignUpClick() {
        this.setState({displaySignUp: true})
    }
    handleLoginClose() {
        this.setState({displayLogin: false})
    }

    render() {
        const displayLogin = this.state.displayLogin

        return(
                <Nav className="top_nav_wrap d-flex flex-row justify-content-around w-100">
                        <NavLink href="/" active>Fieri Finder</NavLink>
                        <FormGroup>
                            <Input
                                type="search"
                                name="search"
                                id="exampleSearch"
                                placeholder="Search"
                            />
                        </FormGroup>
                    <div>
                        <Button onClick={() => (this.handleLoginClick())} outline color="secondary">Log In</Button>{' '}
                        <Button onClick={() => (this.handleSignUpClick())} color="danger">Sign Up</Button>{' '}
                        {displayLogin ? <AuthModal  />:null}
                        <AmplifySignOut style={{display: "none"}}/>
                    </div>
                </Nav>
        )
    }
}
export default TopNav;
