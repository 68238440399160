import React from "react";
import './ListViewBtn.scss'
import {NavLink} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl } from "@fortawesome/free-solid-svg-icons";


const ListViewBtn = () => {
    return (
        <div id="list_view_wrap">
            <NavLink to="/listview"   activeStyle={{
                fontWeight: "bold",
                color: "red"
            }} activeClassName="selected">
                <FontAwesomeIcon className="fa-lg" color="white" icon={faListUl} />
            </NavLink>
        </div>
    )
}

export default ListViewBtn;