import React, { Component } from "react";
import Mapping from "../../components/Mapping/Mapping";
import TopNav from "../../components/TopNav/TopNav";

class ListView extends Component {

    render() {
        return(
            <div>
                <TopNav />
                <Mapping />
            </div>
        )
    }
}
export default ListView;