import React from "react";
import './InfoWindowEx.scss'
import {Button} from 'reactstrap'

const InfoWindow = (props) => {

    const { name, phone, street, city, zip } = props;
    return (
        <div className="info_window">
            <div style={{textAlign: "left", padding: "15px 15px 0 15px", width: "340px"}}>
                <h4>{name}</h4>
                <div className="">
                    <p>{phone}</p>
                    <div>
                        <p>{street}, {city}, {zip}</p>
                    </div>
                </div>
            </div>
            <Button style={{background: "#EB5757", outline: "none", border: "none", marginBottom: "20px"}}>More Info</Button>
        </div>
    );
};

export default InfoWindow;