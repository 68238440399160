import React, { Component } from 'react';
import GoogleMapReact  from 'google-map-react';
import mapStyles from "../../mapStyles";
import Marker from '../Marker/Marker';
import dataList from '../../data/newDDD.json';
import InfoWindow from  '../InfoWindowEx/InfoWindowEx'
import TopNav from "../TopNav/TopNav";
import SideBar from "../SideBar/SideBar";
import ListViewBtn from "../ListViewBtn/ListViewBtn";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faCompass } from "@fortawesome/free-solid-svg-icons";

//Google map styles
const options = {
    styles: mapStyles,
    disableDefaultUI: true,
    zoomControl: true,
};

class NewMap extends Component {
    static defaultProps = {
        center: {
            lat: 33.9803,
            lng: -118.4517
        },
        zoom: 11
    };
    state = {flavorTownhall: {}}
    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
            places: maps.places
        });
    };

    render() {
        const newDataList = dataList.slice(0,500)
        const  handleMarkerClick=(e,flavortown)=>{
            console.log(flavortown.phone)
            const request = {
                phoneNumber: flavortown.phone,
                fields: ["name", "geometry"]
            };
            const service = new this.state.mapApi.places.PlacesService(this.state.mapInstance)
            console.log(service)
            service.findPlaceFromPhoneNumber(request, (results, status) => {
             console.log(status);
            })
            this.setState({flavorTownhall:flavortown})
        }
        let selected = false
        if(Object.keys(this.state.flavorTownhall).length !== 0){
            selected = this.state.flavorTownhall
        }
        const markers = newDataList.map((flavortown)=>
            <Marker
                key={`${flavortown.phone}-${flavortown.episode.number}-${flavortown.name}`}
                lat={flavortown.lat}
                lng={flavortown.lng}
                flavortown={flavortown}
                onClick={(e)=>handleMarkerClick(e,flavortown)}
            />
        );
        return (
            <div>
                <TopNav />
                <ListViewBtn />
                <SideBar />
                <div style={{ height: '100vh', width: '100vw' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, libraries: ['places']}}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        options={options}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                    >
                        {markers}
                        {selected ? (
                            <InfoWindow
                                {...this.state.flavorTownhall}
                                onCloseClick={() => {
                                    this.setState({flavorTownhall:false});
                                }} />
                        ) : null}
                    </GoogleMapReact>
                </div>
            </div>
        );
    }
}

export default NewMap;
