import React, { Component } from "react";
import Header from "../../components/Header/Header";

class Profile extends Component {
    render() {
        return(
                <Header />
        )
    }
}
export default Profile;