/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_cognito_identity_pool_id": "us-west-2:dba8b294-1536-47fb-9a97-ef7a56894cf6",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_FTPMLD9yG",
    "aws_user_pools_web_client_id": "3pbfugu3p8opf61jqm87mmajq5",
    "oauth": {}
};


export default awsmobile;
