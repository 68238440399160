import React, {Component} from "react";
import LgCard from "../LgCard/LgCard";
import DataList from '../../data/newDDD.json';

class Mapping extends Component {
    state={
        data: DataList
    };

    render() {
        const sliceData = this.state.data.slice(1,50)
        let myCards= sliceData.map(
            el=>{
                return <LgCard key={el.name+el.phone+Math.random()} name={el.name} phone={el.phone} street={el.street} city={el.city} state={el.state} />
            })
        return(
            <div style={{height: "100%"}}>
                {myCards}
            </div>
        )
    }
}

export default Mapping;