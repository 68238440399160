import React, {Component} from "react";
import {Button} from "reactstrap";
import './DetailsScreen.css'

class DetailsScreen extends Component {
    render() {
        return(
            <div className="deats_container">
                <div className="deats_header">
                    {/*<img src="" alt=""/>*/}
                </div>
                <div className="info_wrap">
                    <div className="col_3" style={{padding: "20px"}}>
                        <div style={{textAlign: "left"}}><h3>Restaurant</h3></div>
                        <div><p>Ratings, Hours, Photos, cuisine type, services (delivery/takeout), Menu, save/favorite</p></div>
                        <div style={{textAlign: "right"}}><h5>$$ | Dist</h5></div>
                    </div>
                    <Button color="danger" style={{borderRadius: "8px"}}><h4 style={{margin: 0, color: "white"}}>Route Me</h4></Button>{' '}
                </div>
            </div>
        )
    }
}
export default DetailsScreen;