import dotenv from 'dotenv';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {BrowserRouter as Router} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';



import Amplify from "aws-amplify";
import awsExports from "./aws-exports";
Amplify.configure(awsExports);

dotenv.config();


ReactDOM.render(
  <Router>
      <App />
  </Router>,
  document.getElementById('root')
);
