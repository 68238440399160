import React from "react";
import { NavLink } from 'react-router-dom';
import { Nav, NavItem} from 'reactstrap';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faListUl, faUser, faMap } from "@fortawesome/free-solid-svg-icons";
import './Nav.scss'

const tabs = [{
    route: "/listview",
    icon: faListUl,
    label: "List View",
},{
    route: "/map",
    icon: faMap,
    label: "Map"
},{
    route: "/profile",
    icon: faUser,
    label: "Profile"
}]

const NavBar = () => {
        return(
            <nav id="bottom_nav" className="bottom-nav navbar fixed-bottom navbar-light" role="navigation">
                <Nav className="w-100">
                    <div className="d-flex flex-row justify-content-around w-100">
                        {
                            tabs.map((tab, index) =>(
                                <NavItem key={`tab-${index}`}>
                                    <NavLink to={tab.route} className="nav-link" activeClassName="active">
                                        <div className="row d-flex flex-column justify-content-center align-items-center">
                                            <FontAwesomeIcon id="nav_icon" size="lg" icon={tab.icon}/>
                                            <div id="nav_label">{tab.label}</div>
                                        </div>
                                    </NavLink>
                                </NavItem>
                            ))
                        }
                    </div>
                </Nav>
            </nav>
    )
}
export default NavBar;