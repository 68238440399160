import React, { Component } from "react";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from "@fortawesome/free-solid-svg-icons";

import './Header.scss'
import Tabs from "../Tabs/Tabs";

class Header extends Component {
    state = {
        name: "User Name",
        img: ""
    }
    render() {
        return(
            <div className="profile-header">
                <div className="header-top col_2">
                    <div>
                    </div>
                    <div className="icon-container">
                        <FontAwesomeIcon size="lg" icon={ faCog }/>
                    </div>
                </div>
                <div>
                    <div className="profile-img">
                        {this.state.img}
                    </div>
                    <div>
                        <h4>{this.state.name}</h4>
                    </div>
                </div>
                <div>
                    <Tabs />
                </div>
            </div>
        )
    }
}
export default Header;