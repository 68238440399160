import React from 'react';
import './Marker.scss';

const Marker = (props) => {

    const { city, color, name, phone, state, zip} = props;
    return (
        <div>
            <div className="pin bounce"
                 style={{ backgroundColor: color, cursor: 'pointer', position:'absolute',}}
                 name={name}
                 onClick={(e) => {
                     props.onClick(e);
                 }}
                 phone={phone}
                 city={city}
                 state={state}
                 zip={zip}
            />

        </div>


    );
};

export default Marker;