import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import './Tabs.scss';

const Tabs = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div className="profile-tabs">
            <Nav tabs>
                <NavItem className="nav-item">
                    <NavLink
                        className={{ active: activeTab === '1' }}
                        onClick={() => { toggle('1'); }}
                    >
                        Saved
                    </NavLink>
                </NavItem>
                <NavItem className="nav-item">
                    <NavLink
                        className={{ active: activeTab === '2' }}
                        onClick={() => { toggle('2'); }}
                    >
                        Visited
                    </NavLink>
                </NavItem>
            </Nav>
        </div>
    );
}

export default Tabs;