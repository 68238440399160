import React, { Component } from "react";
// import {Link} from "react-router-dom";
// import {
//     Card,
//     // CardImg,
//     CardBody,
//     CardTitle, CardSubtitle, Button
// } from 'reactstrap';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faHeart } from "@fortawesome/free-solid-svg-icons";

import './SideBar.scss'
import Mapping from "../Mapping/Mapping";


class SideBar extends Component {
    render() {
        return(
            <div id="sidebar_wrap">
                <Mapping />
            </div>
        )
    }
}
export default SideBar;