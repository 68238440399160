import React, { Component } from "react";
import {Link} from "react-router-dom";
import {
    Card,
    CardImg,
    CardBody, CardText, Button
} from 'reactstrap';
import './LgCard.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import SampleImg from '../../fieri-sample-img.jpg'

const toggleFavAction = () => {

}

class LgCard extends Component {

    render() {
        return(
            <div className="card_list">
                <Card className="card_wrap">
                    <div className="heart_icon">
                        <FontAwesomeIcon size="sm" onClick={() => toggleFavAction} icon={ faHeart }/>
                    </div>
                    <div id="img_wrap">
                        <CardImg top width="100%" src={SampleImg} alt="Card image" />
                    </div>
                    <CardBody className="card_body">
                        <h3>{this.props.name}</h3>
                        <CardText>{this.props.street}, {this.props.city}, {this.props.state}</CardText>
                        <CardText>{this.props.phone}</CardText>
                        <div className="col_2">
                            <div><Button>Route Me</Button></div>
                            <Link to="/listview/details"><Button>More Info</Button></Link>
                        </div>
                    </CardBody>
                </Card>
                <div className="divider"> </div>
            </div>

        )
    }
}
export default LgCard;