import React from 'react';
import './App.css';

import NavBar from "./components/Nav/Nav";
import { Route, Switch } from "react-router-dom";
import Profile from "./screens/Profile/Profile";
import ListView from "./screens/ListView/ListView";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import NewMap from "./components/NewMap/NewMap";
import DetailsScreen from "./screens/DetailsScreen/DetailsScreen";
// import { withAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';

function App() {
  return (
    <div className="App">
        <Switch>
            <>
            <Route exact path="/" component={ NewMap }  />
            <Route exact path="/profile" component={ Profile }  />
            <Route exact path="/listview" component={ ListView }  />
            <Route exact path="/listview/details" component={ DetailsScreen } />
            </>
        </Switch>
    </div>
  );
}

export default App;
