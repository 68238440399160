import React, {Component} from "react";
import '../InfoWindowEx/InfoWindowEx.scss'
import {AmplifySignIn, AmplifyAuthenticator } from '@aws-amplify/ui-react';
import './AuthModal.scss'

class AuthModal extends Component {


    render() {

        return (
            <div className="authModal">
                    <AmplifyAuthenticator>
                        <AmplifySignIn
                            headerText="Welcome Back Fieri Friend!"
                            slot="sign-in"
                        >
                        </AmplifySignIn>
                    </AmplifyAuthenticator
                        >
            </div>
                )

}}

export default AuthModal;
